import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { useContext, useState, useEffect } from 'react';

import noteContext from './NoteContext';


import API from "./API";


import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Snackbar from '@mui/material/Snackbar';


import Form from 'react-bootstrap/Form';

import DoneAllIcon from '@mui/icons-material/DoneAll';
export const EditStudent = () => {





    //const [student, setstudent] = useState( );


    const [fields, setfields] = useState([]);



    const [message, setmessage] = useState("");

    const [open, setOpen] = useState(false);


    const data = useContext(noteContext);
    let token = data.token;
    const { sr } = useParams()



    const captureData = async (index, label, value) => {





        let data = [...fields];
        data[index].label = label;
        data[index].value = value;

        setfields(data);




    }




    const loadform = async (sr) => {

        const res = await API.get("StudentFormFields/" + sr, {
            headers: {
                'content-type': 'text/json', Authorization: 'Bearer ' + token
            }
        });




        setfields(res.data);


    }





    const handlesubmit = async () => {



        var student_data = {

            "sr": sr,
            "json": fields
        }

        const res = await API.post("updateStudent", student_data, {
            headers: {
                'content-type': 'text/json', Authorization: 'Bearer ' + token
            }
        });

        const dt = res.data;

        setmessage(dt.message);


        setOpen(true);


    }



    useEffect(() => {




        loadform(sr);


    }, []);







    return (

        <>
            {fields.map((item, index) => (

                <Form.Group key={index} className="mb-3"  >
                    <Form.Label>{item.name}   </Form.Label>
                    <Form.Control onChange={(e) => captureData(index, item.label, e.target.value)}
                        type={item.type}
                        value={item.value}
                    />
                </Form.Group>



            ))}



            <Button onClick={handlesubmit} variant="contained"> <DoneAllIcon />    </Button>


            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={() => setOpen(false)}
                message={message}

            />


        </>

    )




}