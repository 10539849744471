

import {

    useQuery,
} from '@tanstack/react-query'

import { useNavigate , useParams  } from "react-router-dom";

import { useContext, useState, useEffect } from 'react';

import Form from 'react-bootstrap/Form';

import DoneAllIcon from '@mui/icons-material/DoneAll';

import API from "./API";


import noteContext from './NoteContext';
 
export const UpdateStudent = () => {


    const [formFields, setFormFields] = useState([
        { name: '', age: '' },
      ])
    
      const handleFormChange = (event, index) => {
        let data = [...formFields];
        data[index][event.target.name] = event.target.value;
        setFormFields(data);
      }
    
      const submit = (e) => {
        e.preventDefault();
        console.log(formFields)
      }
    

      
    const data = useContext(noteContext);
    let token = data.token;
    const loadform = async () => {

        const res = await API.get("master/StudentFormFields", {
            headers: {
                'content-type': 'text/json', Authorization: 'Bearer ' + token
            }
        });

        var fields =res.data;
       var index=0;

           
    let dataar = [...formFields];

    dataar.push(fields); 
 
    setFormFields(dataar);

 

         console.log(formFields);
        //setfields(res.data);



    }



    useEffect(() => {




        loadform(); 


    }, []);

      const addFields = () => {
        let object = {
          name: '',
          age: ''
        }
    
        setFormFields([...formFields, object])
      }
    
      const removeFields = (index) => {
        let data = [...formFields];
        data.splice(index, 1)
        setFormFields(data)
      }
    
      return (
        <div className="App">
          <form onSubmit={submit}>
            {formFields.map((form, index) => {
              return (
                <div key={index}>
                  <input
                    name='name'
                    placeholder='Name'
                    onChange={event => handleFormChange(event, index)}
                    value={form.name}
                  />
                  <input
                    name='age'
                    placeholder='Age'
                    onChange={event => handleFormChange(event, index)}
                    value={form.age}
                  />
                  <button onClick={() => removeFields(index)}>Remove</button>
                </div>
              )
            })}
          </form>
          <button onClick={addFields}>Add More..</button>
          <br />
          <button onClick={submit}>Submit</button>
        </div>
      );
}


 