import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { useContext } from 'react';
import Avatar from '@mui/material/Avatar';


import { purple, red } from '@mui/material/colors';


import { Link, NavLink } from 'react-router-dom';
import noteContext from './NoteContext';

import logo from '../Logo.png';

const drawerWidth = 240;






function DrawerAppBar(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };
    const navigate = useNavigate();


    const a = useContext(noteContext);


    let navItems = [];

    if (!a.login_status) {
        navItems = ['Home', 'Login'];
    } else if (a.role === "10") {


        navItems = ['Home', 'homeworks', 'Teacher_report', 'Student_report','Student_print_report', 'teachers', 'Logout'];
    }
    else {

        navItems = ['Home', 'homeworks', 'report', 'Logout'];

    }

    console.log(a.role);

    function sendto(provider) {
        if (provider === "Home")
            navigate("/");
        else
            navigate(provider)



    }


    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" className='sakshbrandingdrawer' sx={{ my: 2 }}>
                <Logo_image />
            </Typography>
            <Divider className='sakshhrminidrawer' />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary={item} onClick={() => sendto(item)} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box className='saksh_box_92' sx={{ display: 'flex' }}>

            <AppBar className='saksh_app_bar' component="nav">
                <Toolbar>
                    <IconButton
                        className="saksh_black_color"


                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        className='saksh_black_color sakshbranding '
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' } }}
                    >
                        <Logo_image />
                    </Typography>



                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>


                        {navItems.map((item) => (


                            <Button key={item} onClick={() => sendto(item)} sx={{ color: '#000' }}>
                                {item}
                            </Button>


                        ))}


                    </Box>


                </Toolbar>
            </AppBar>

            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>


        </Box>
    );
}



export default DrawerAppBar;







function Logo_image() {
    // Import result is the URL of your image
    return <> <NavLink className="sakshbrand" to="/">
        <img src={logo} height="50px" alt="Student" />
        Student </NavLink> </>;
}
