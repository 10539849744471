import axios from "axios"


const API =axios.create({

    
    baseURL : "https://schoolv11.sakshamapp.com/api/"


});


export default API;


