import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

import { useContext } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Snackbar from '@mui/material/Snackbar';

import Box from '@mui/material/Box';

import API from "./API";

import LaunchIcon from '@mui/icons-material/Launch';

import Table from 'react-bootstrap/Table';
import noteContext from './NoteContext';


import AssignmentIcon from '@mui/icons-material/Assignment';
export default function SearchStudents() {

    const { homework_id } =87;

    const [message, setmessage] = useState("");
    const [isDisabled, setDisabled] = useState(true);

    const [open, setOpen] = useState(false);

    const [HomeWork, setHomeworkDetail] = useState();

    // const [userData, setUserdata]= useState([]);
    const [students, setStudents] = useState([]);
    const [error, setError] = useState("%");
    const [loadingbar, setHideLoading] = useState(false);

    const [student_class, setClass] = useState(1);
    const [student_name, setStudentName] = useState();
    const navigate = useNavigate();
    const a = useContext(noteContext);
    let token = a.token;


    const fetchStudentsData = async () => {

        try {
            const res = await API.get("students/" + student_class + "/" + student_name, {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            setStudents(res.data);

            setHideLoading(true);

            setDisabled(true);



        }
        catch (error) {
            setError(error);

        }


    };

    useEffect(() => {

        if (a.login_status === null)
            navigate("/login")


        else {

           // alert(homework_id);

           // fetchHomeWorkInfo(87);

           setHideLoading(true);
        }



    }, []);




    const handleChange = (e) => {
        const { name, checked } = e.target;

        setDisabled(false);

        if (name === "allselect") {
            const checkedvalue = students.map((item) => { return { ...item, isChecked: checked } });

            setStudents(checkedvalue);
        } else {


            const checkedvalue = students.map((item) =>
                item.sr === name ? { ...item, isChecked: checked } : item);





            setStudents(checkedvalue);


        }


    }

    const handlesubmit = async () => {
        setDisabled(true);
        const filteredArr = students.filter(e => e.isChecked === true);


         
        var reqdata = {

            "srs": filteredArr, 
        }

        console.log(reqdata);

       


 
        const res = await API.post("get_student_print_report_url", reqdata, {
            headers: {
                'content-type': 'text/json', Authorization: 'Bearer ' + token
            }
        });




        const dt = res.data;
         

    window.location.replace(dt);
        setmessage(dt.message);
 


        setOpen(true);


    }




    const fetchHomeWorkInfo = async (homework_id) => {

        try {
            const res = await API.get("master/homework/" + homework_id, {
                headers: {
                    'content-type': 'text/json', Authorization: 'Bearer ' + token
                }
            });
            setHomeworkDetail(res.data);
            setHideLoading(true);

        }
        catch (error) {

            console.log(error);


        }


    };





    //if (homework_id === undefined) return <> </>;


    if (!loadingbar)


        return (


            "Loading... please wait "
        )


    return (

        <div className="sakshmb">


            <div className="mb-3">

                <h1>Assign Homework</h1>


                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >




                    <TextField
                        className="mr5"
                        required

                        label="Type Class"
                        id="type_class"

                        onChange={(e) => setClass(e.target.value)}


                        autoFocus
                    />





                    <TextField
                        className="mr5"
                        required

                        label="Student Name"
                        id="type_studentname"

                        value={student_name}

                        onChange={(e) => setStudentName(e.target.value)}



                    />




                    <Button onClick={fetchStudentsData} variant="contained">Search Students</Button>

                </Box>
            </div>
















            <Table striped responsive bordered hover size="sm">

                <thead>

                    <tr>

                        <th>
                            <input type="checkbox" name="allselect"
                                checked={!students.some((user) => user?.isChecked !== true)} onChange={handleChange} /> &nbsp;
                          
                        </th>


                        <th>
                            SR
                        </th>

                        <th>
                            Name

                        </th>

                        <th>
                            Class
                        </th>

                        <th>
                            Grade
                        </th>
                        <th>
                            Grade
                        </th>

                        <th>Father Name

                        </th>

                        <th>Mother Name

                        </th>

                    </tr>
                </thead>
                <tbody>

                    {students.map((item, index) => (


                        <tr key={index}>

                            <th> <input type="checkbox" name={item.sr} checked={item?.isChecked || false}
                                onChange={handleChange} />
                                
                                &nbsp;
                                <Link target="_blank"   to={`/student/${item.sr}`}> <LaunchIcon /></Link>
                            
 
                            
                            <Link  target="_blank"  to={`/homework/${item.sr}`}><AssignmentIcon/> </Link>
                                
                                </th>


                            <td>   {item.sr}  
                         
                            
                            
                            </td>

                            <td>   {item.name} </td>

                            <td>   {item.class} </td>

                            <td>   {item.grade} </td>

                            <td>   {item.mobile} </td>

                            <td>   {item.father_name} </td>

                            <td>   {item.mother_name} </td>



                        </tr>
                    ))}
                </tbody>
            </Table>




            <Button onClick={handlesubmit} variant="contained" disabled={isDisabled}>Print </Button>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={() => setOpen(false)}
                message={message}

            />


        </div>

    )
};
