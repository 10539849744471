import noteContext from "./NoteContext";

import { useNavigate } from "react-router-dom";
import {Navigate } from "react-router-dom"


const NoteState = (props) => {

    

    const navigate = useNavigate();

    const login_status = localStorage.getItem('login_status');
    const user = localStorage.getItem('user');  const role = localStorage.getItem('role');
    const subscription_id=localStorage.getItem('subscription_id');

    const token=localStorage.getItem('token');
    const subjects=localStorage.getItem('subjects');
    
 
    const state = {

        "user": user,
        "role": role,
        "login_status": login_status,
        "subscription_id":subscription_id,
        "token":token  ,  
        "subjects":subjects


    }
 


    return (
        <noteContext.Provider value={state}>

            {props.children}
        </noteContext.Provider>

    )



}

export default NoteState;
