import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

import { useContext } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Snackbar from '@mui/material/Snackbar';

import Table from 'react-bootstrap/Table';
import Box from '@mui/material/Box';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import API from "./API";
import LaunchIcon from '@mui/icons-material/Launch';
import AssignmentIcon from '@mui/icons-material/Assignment';
import noteContext from './NoteContext';

import Checkbox from '@mui/material/Checkbox';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

function inArray(needle, haystackstr) {
    if (haystackstr === null) return false;
    var haystack = JSON.parse(haystackstr);


    var length = haystack.length;
    for (var i = 0; i < length; i++) {
        if (haystack[i] == needle) return true;
    }
    return false;
}

function Teachers() {
    const [data, setData] = useState([]);
    const [subjects, setSubjects] = useState([]);

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [mobile, setMobile] = useState();



    const [open, setOpen] = useState(false);

    const [message, setmessage] = useState();

    const [loadingbar, setHideLoading] = useState(false);


    const navigate = useNavigate();
    const a = useContext(noteContext);
    let token = a.token;



    const print_subjects = (teacher, subs) => {




        let checked = true;
        let Unchecked = false;


        return (

            < >



                {subs.map((st, i) => (


                    <div key={i}>

{i % 5 == 0 && <hr />}

                        {inArray(st, teacher.subjects) && <>
                            <input key={i}
                                checked={checked}
                                onChange={e => handleChange(teacher.id, st, e)}

                                type="checkbox" className="pr10" />    {st}    </>}



                        {!inArray(st, teacher.subjects) && <>
                            <input key={i}
                                checked={Unchecked}
                                onChange={e => handleChange(teacher.id, st, e)}

                                type="checkbox" className="pr10" />     {st}  </>}



                    </div>

                ))

                }


            </>

        )

    }

    const handleChange = async (teacher_id, subject, event) => {






        var teacher_data = {

            "teacher_id": teacher_id,
            "subject": subject,
            "checked": event.target.checked
        }

        const res = await API.post("master/update_teacher_subject", teacher_data, {
            headers: {
                'content-type': 'text/json', Authorization: 'Bearer ' + token
            }
        });

        const dt = res.data;

       

        fetch_teachers_list();


    };


    const handleaddteacher = async () => {
        var teacher_data = {

            "name": name,
            "email": email,
            "mobile": mobile
        }

        const res = await API.post("master/add_teacher", teacher_data, {
            headers: {
                'content-type': 'text/json', Authorization: 'Bearer ' + token
            }
        });

        const dt = res.data;

        setmessage(dt.message);


        setOpen(true);

        fetch_teachers_list();


    };

    const delete_teacher = async (teacher) => {

        const res = await API.post("master/delete_teacher", teacher, {
            headers: {
                'content-type': 'text/json', Authorization: 'Bearer ' + token
            }
        });

        const dt = res.data;

        setmessage(dt.message);


        setOpen(true);

        fetch_teachers_list();
    }

    const fetch_teachers_list = async () => {





        const res = await API.get("master/teachers_list", {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            }
        });
        setData(res.data.teachers);
        setSubjects(res.data.subjects);

        setHideLoading(true);



    };

    useEffect(() => {

        if (a.login_status === null)
            navigate("/login")


        else {

            fetch_teachers_list();


        }



    }, []);








    return (

        <div className="sakshmb">



            Name
            <input onChange={(e) => setName(e.target.value)} value={name} />
            Email
            <input onChange={(e) => setEmail(e.target.value)} value={email} />
            mobile
            <input onChange={(e) => setMobile(e.target.value)} value={mobile} />

            <button onClick={handleaddteacher}  >Add Teacher</button>

            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={() => setOpen(false)}
                message={message}

            />



            <div className="card">




            </div>



            {data.map((item, index) => (




                <Card key={index} >
                    <CardContent>




                        <div>
                            {item.name}  {item.mobile}   {item.email}
                        </div>

                        <div>
                            {print_subjects(item, subjects)}

                        </div>





                    </CardContent>
                    <CardActions>
                        <Button onClick={() => delete_teacher(item)}
                            variant="contained"> <DeleteForeverIcon /></Button>
                    </CardActions>
                </Card>


            ))}




        </div>

    )
}

export default Teachers;