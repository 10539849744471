import * as React from 'react';
import { useNavigate } from "react-router-dom";

import { useContext, useState, useEffect } from 'react';

import noteContext from './NoteContext';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import API from "./API";



import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Snackbar from '@mui/material/Snackbar';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';




function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export const Profile = () => {


    const [message, setmessage] = useState("");

    const [subscription, setsubscription] = useState("");


    const [mobile, setMobile] = useState("");
    const [name, setName] = useState("");
    const [open, setOpen] = useState(false);
    //  const [value, setValue] = useState('1');



    const data = useContext(noteContext);



    const [user, setUser] = useState(data.user);
    let token = data.token;


    const [value, setValueTabs] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValueTabs(newValue);
    };


    const handlesubmit = async () => {

        const reqdata = {

            mobile: mobile,
            name: name
        };


        if (mobile.length != 10) {
            setmessage("Please provide correct mobile.");
            setOpen(true);
            return;
        }


        if (name.length < 1) {
            setmessage("Please provide correct mobile.");
            setOpen(true);
            return;
        }




        const res = await API.post("updateprofile", reqdata, {
            headers: {
                'content-type': 'text/json', Authorization: 'Bearer ' + token
            }
        });

        const dt = res.data;
        setmessage(dt.message);
        setOpen(true);

        setUser(dt.user);




    }



    const fetchSelf = async () => {

        try {
            const res = await API.get("me", {
                headers: {
                    'content-type': 'text/json', Authorization: 'Bearer ' + token
                }
            });
            const dt = res.data;



            setUser(dt.user);
            setName(dt.user.name);
            setMobile(dt.user.mobile);



            setsubscription(dt.user.subscription);






        }
        catch (error) {
            console.log(error);

        }


    };

    useEffect(() => {






        fetchSelf();



    }, []);








    if (!user) return <>  </>;




    return (

        <>

            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Profile" value="1" />
                            <Tab label="Update Profile" value="2" />

                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <TableContainer  >
                            <Table   >
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell  >{user.name}</TableCell>

                                    </TableRow>

                                    <TableRow>
                                        <TableCell>Mobile</TableCell>
                                        <TableCell  >{user.mobile}</TableCell>

                                    </TableRow>


                                    <TableRow>
                                        <TableCell>Email</TableCell>
                                        <TableCell  >{user.email}</TableCell>

                                    </TableRow>


                                    <TableRow>
                                        <TableCell>Subscription</TableCell>
                                        <TableCell  >{subscription.plan_name}</TableCell>

                                    </TableRow>

                                    <TableRow>
                                        <TableCell>Subscription Duration </TableCell>
                                        <TableCell  >{subscription.subscriptions_start_date}  -  {subscription.subscriptions_end_date}</TableCell>

                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>
                    <TabPanel value="2">  <div className="mb-3">




                        Name
                        <TextField
                            margin="normal"
                            required
                            fullWidth



                            value={name}

                            onChange={(e) => setName(e.target.value)}


                            autoFocus
                        />



                    </div>

                        <div className="mb-3">




                            Mobile
                            <TextField
                                margin="normal"
                                required
                                fullWidth

                                value={mobile}



                                onChange={(e) => setMobile(e.target.value)}


                                autoFocus
                            />



                        </div>
                        <div className="mb-3">

                            <Button fullWidth onClick={handlesubmit} size="large" variant="outlined">Update</Button>

                            <Snackbar
                                open={open}
                                autoHideDuration={2000}
                                onClose={() => setOpen(false)}
                                message={message}

                            />
                        </div>



                    </TabPanel>

                </TabContext>
            </Box>







        </>

    )
}