
import { Component } from 'react';

class TermsConditions extends Component {



  render() {
    return <>
    
    
    <p><strong><u>Terms &amp; Conditions</u></strong></p>
<ol>
<li><strong>Preamble</strong></li>
</ol>
<ul>
<li>1. <strong>Saudebazi</strong> (collectively and individually including website, mobile applications or other digital services using the name, the <strong>"Platform"</strong>) is an online auctions platform for agricultural commodities where the suppliers can gauge the demand/supply for the day, traders can bid/ sell for deals in agriculture commodities, view deals across geographical locations and discover better prices, and a digital service platform to facilitate business-to-business transactions by providing buyers and sellers ancillary and tertiary services in agriculture.</li>
<li>2. AgriGravity Technologies Private Limited (the <strong>&ldquo;Company&rdquo;</strong>) owns and maintains this website www.saudebazi.com mobile-optimized versions of the Website, digital applications, plug-ins and other media formats for use and promotion of the Company.(collectively and individually, the <strong>&ldquo;Services&rdquo;</strong>)</li>
<li>3. Access to and use of entire Services are governed by the terms and conditions set out herein below in this document (<strong>&ldquo;Terms of Use&rdquo;</strong>).</li>
<li>4. These Terms of Use form a legal and binding agreement between the User and the Company and is made pursuant to the provisions of the Information Technology Act, 2000 and is subject to the laws of India. For the purpose of these Terms of Use, wherever the context so requires &ldquo;<strong>You</strong>&rdquo; or &ldquo;<strong>User</strong>&rdquo; (including any grammatical variations thereof) shall mean any person or entity who engages or uses or accesses the Services and includes a legal person/entity whose representatives (whether authorized or not) access the Services on their behalf.</li>
<li>5. Your access or use of the Services implies that you have read, understood and agreed to abide by the Terms of Use. These Terms of Use read together with the Company&rsquo;s Privacy Policy available at [www.saudebazi.com/privacy-policy] (<strong>&ldquo;Privacy Policy&rdquo;</strong> ) govern usage of the Services. If you disagree with any part of these Terms of Use, please do not access or use the Services thereof.</li>
<li>6. These Terms of Use supersede all previous oral and written terms and conditions (if any) communicated to You.</li>
</ul>
<p>&nbsp;</p>
<ol>
<li><strong>Interpretations</strong></li>
</ol>
<ul>
<li><strong>1. </strong><strong>&ldquo;Auction&rdquo; </strong>means buying/selling of agricultural commodities by the process of bidding.</li>
<li><strong>2. </strong><strong>&ldquo;Deal&rdquo; </strong>means auctions for buying or selling taking place of various commodities by specific sellers/buyers or in a specified geographical location.</li>
<li><strong>3. </strong><strong>&ldquo;Intellectual Property&rdquo; </strong>means all content included in or made available through the Platform such as text, trade secrets, business processes, graphics, logos, button icons, images, code, script, APIs etc. covered under the various laws of copyright, trademark, patents, designs etc.</li>
<li><strong>4. </strong><strong>&ldquo;Trader&rdquo; </strong>means a buyer or seller participating in the auctions by way of creating deals.</li>
<li><strong>5. </strong><strong>&ldquo;Third Party Services&rdquo; </strong>means services provided on the Platform by third parties which are facilitated by the Company through its various arrangements and tie-ups.</li>
</ul>
<p><strong>&nbsp;</strong></p>
<ol>
<li><strong>General / Services</strong></li>
</ol>
<ul>
<li>1. The Services on the platform are available for Users and Traders who are engaged in trading of agriculture commodities. The Company reserves the right for access and usage of the Services or its components and may provide access to certain parts of the Services on a Log-In/ Subscriber basis.</li>
<li>2. The Company may provide grades/ ratings to the buyers and suppliers listed on the Platform. For detailed information on the registration as a buyer and supplier, methodology for rating of the suppliers and the buyers, placing orders and making payments, please refer to the customer support or FAQs for further queries.</li>
<li>3. The User of the Platform unequivocally declares and agrees that the User is a natural / legal person who has attained the age of at least 18 years and is legally allowed to enter into a contract in India. Where the User represents a person on whose behalf he is accessing or using the Services, it is presumed that the person so accessing or using the Platform has express or implied consent from such person to do so.</li>
<li>4. For using Log-In/ Subscription based services on the Platform and participating in the Platform auctions, a User may be required to submit specified details and documents (as may be amended from time to time) to meet the registration requirements as listed on the Platform as and when required by the Company. For details please refer to the &ldquo;User Accounts, License and Access&rdquo; section of the Terms of Use.</li>
<li>5. The Company reserves the right to update or renew the Terms of Use without prior intimation.</li>
<li>6. The Company may assign, transfer, and subcontract its rights and/or obligations under these Terms of Use without any notification or consent required. However, a User shall not be permitted to assign, transfer, or subcontract any of its rights and/or obligations under these Terms of Use.</li>
<li>7. If any provision of these Terms of Use is found to be unenforceable or invalid under any applicable law, such unenforceability or invalidity shall not render these Terms of Use unenforceable or invalid as a whole, and such provisions shall be deleted without affecting the remaining provisions herein.</li>
</ul>
<ol>
<li><strong>User Accounts, License and Access</strong></li>
</ol>
<ul>
<li>1. For Accessing/ Using the platform a User has to get registered on the platform by providing the true and correct relevant information which may include details of the user including geographical location, phone number etc. A User must be at least 18 (eighteen) years of age to be eligible to register an account on the Platform.</li>
<li>2. After the registration process is completed, a profile shall be created along with a User Account of the User and for each log-in a user may be required to authenticate via OTP (one time password via email or phone)or any such method as may be prescribed.</li>
<li>3. Users will be solely responsible for maintaining the confidentiality of their User Account and login details, and the Company will not be responsible for misuse of your User Account by any third party, whether authorized by the User or not. A User may never use another User&rsquo;s account without permission of such User and the Company. Users are also prohibited from sharing their log-in details or allowing anyone to access their account or do anything that might put their User Account at risk.</li>
<li>4. Users are responsible for any activity on the Platform arising out of any failure to keep their User Account confidential and may be held liable for any losses arising out of such a failure. User agrees to notify the Company immediately of any unauthorized use of their User Account or any other breach of security.</li>
<li>5. For accessing and creating deals, or for participating in an auction for trading, a User may be required to submit KYC documents (as prescribed by the Company) as and when required by the Company.</li>
<li>6. Users may be charged for certain Services available on the platform. The Company grants limited license to access and use the Platform for availing the Services, but not to download any material from it or modify it, or any portion of it, except with the Company&rsquo;s express or written consent. Any unauthorized access to the Platform or any networks, servers or computer systems connected to the Platform and any attempt to modify, adapt, translate or reverse engineer any part of the Platform or re-format or frame any portion of the pages of the Platform is not permitted.</li>
<li>7. The license is non-transferable and does not permit any resale or commercial use of this Platform or its contents; any downloading or copying of account information for the benefit of anyone other than Your authorized use; or any use of data mining, robots, or similar data gathering and extraction tools.</li>
<li>8. In addition to other prohibitions as set forth in the Terms of Use, You are prohibited from using the Platform or the Services, whether directly or indirectly (collectively, the &ldquo;<strong>Prohibited Uses</strong>&rdquo;):</li>
</ul>
<ul>
<li>for any unlawful purpose;</li>
<li>to solicit others to perform or participate in any unlawful acts;</li>
<li>to violate any applicable regulations, rules, laws, or local ordinances;</li>
<li>to infringe upon or violate Our intellectual property rights or the intellectual property rights of others;</li>
<li>to harass, abuse, insult, harm, defame, slander, disparage or intimidate any person, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;</li>
<li>to submit false or misleading information;</li>
<li>to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the internet;</li>
<li>to collect or track the personal information of others;</li>
<li>to spam, phish or pharm;</li>
<li>harvest or collect any information available on the Platform including using any robot, spider, site search or retrieval application, or other manual or automatic device or process to retrieve, index, or data-mine;</li>
<li>for any obscene or immoral purpose;</li>
<li>to interfere with or circumvent the security features of the Platform or any related website, other websites, or the Internet; or</li>
<li>to allow or permit any third-party to collect any data, information or User Content from the Platform or the Services.
<ul>
<li>9. The Company reserves the right to revoke or suspend a User Account without prior intimation to the User.</li>
<li>10. On failure to abide by any of the conditions prescribed in the Terms of Use or any breach thereof, the Company may suspend/revoke a User Account or may blacklist a User from the platform and the User will be solely liable for such action and any loss caused due to such behavior by the User to the Company. The Company may restore the User Account on any rectification done by the User or any explanation rendered by the User thereof.</li>
</ul>
</li>
</ul>
<ol>
<li><strong>Intellectual Property</strong></li>
</ol>
<ul>
<li>1. All Intellectual Property and their rights available on the platform are owned by the Company or have been duly licensed by third parties to the Company.</li>
<li>2. User acknowledges and agrees that the Company owns all legal right, title, and interest in and to the Services and the Platform, including any Intellectual Property Rights which subsist in the Services (whether those rights happen to be registered or not, and wherever in the world those rights may exist).</li>
<li>3. You may not use content from Our Platform unless You obtain our express written permission from Us or the content&rsquo;s owner, as the case may be, or are otherwise permitted by law. These terms do not grant You the right to use any intellectual property, including trade marks, brand names, branding or logos used on the Platform. You must not remove, obscure or alter any legal notices displayed in or along with the Platform and the Services.</li>
</ul>
<ol>
<li><strong>Privacy</strong></li>
</ol>
<p>Please refer to our Privacy Policy [www.saudebazi.com/privacy-policy] to review and understand what user information we gather and how we deal with personal information.</p>
<ol>
<li><strong>Limitation of Liability &amp; Disclaimer</strong></li>
</ol>
<ul>
<li>1. As Users of the Platform and the Services, the Users shall use the Platform and the Services at their own risk.</li>
<li>2. In no event shall the Company, its affiliates or its respective officers, managers, members, directors, employees, successors, assigns, subsidiaries, suppliers, attorneys or agents, be liable to a user for any direct, indirect, incidental, special, punitive, consequential or exemplary damages (including but not limited to loss of business, revenue, profits, use, data or other economic advantage) whatsoever resulting from any (i) access to or use of the Platform; (ii) errors, mistakes, or inaccuracies of data, Intellectual Property Rights, content, information, materials or substance of the Platform; (iii) any unauthorized access to or use of our secure servers and / or any and all personal information and / or financial information stored therein; (iv) any bugs, viruses, trojan horses, or the like which may be transmitted to or through the website/application/code/script by any third party; (v) any interruption or cessation of transmission to or from the Platform; (vi) any errors or omissions in any data, content, information, materials or substance of the Platform or content; (vii) any failed negotiations or auctions for purchase/sale of ownership of any listed commodity on the Platform, any disputes that arise during or after the negotiation for purchase of fractional ownership of any listed commodity on the platform, or any other dispute that arises between users of the Platform; (viii) any defamatory, offensive, or illegal conduct of any third party or user.</li>
<li>3. As a facilitator of third party service providers, the platform may provide services from other entities including but not limited to finance, logistics, warehousing etc. However, the Company will not be liable for any deficiency of service or unfair trade practices or to indemnify any loss caused to the User by availing such Third-party services. All Third-party services will be governed by their respective agreements as entered upon by the User and such Third-Party while availing such services.</li>
<li>4. All of the content available on the Platform is for reference purpose only. A User is required to exercise its discretion while relying on any of the information/data provided on the Platform and the Company will not be liable for any loss caused due to reliance on any of the content available on the Platform by the User.</li>
<li>5. In any case where the Company or the Platform is held liable for any loss caused due to the User, its total liability shall not exceed the amount charged by the Company from the User for accessing and using the Platform and the Services.</li>
</ul>
<ol>
<li><strong>Indemnity</strong></li>
</ol>
<p>You, the User agree to indemnify, save, and hold the Company or its affiliates, contractors, employees, officers, directors, agents and its third party associates, licensors, and partners harmless from any and all claims, demands, losses, damages, and liabilities, costs and expenses, including without limitation legal fees and expenses, arising out of or related to the User Content provided&nbsp; by You and/or Your use or misuse of the Services or Platform and/or any violation by You of these Terms of Use, or any breach of the representations, warranties, and covenants made by You herein or Your infringement of any intellectual property or other right of any person or entity, or as a result of any threatening, libelous, obscene, harassing or offensive material / User Content posted/ transmitted by You on / through the Platform or any claims made by a third-party against Us arising out of Your acts or omissions. The Company reserves the right, at Your expense, to assume the exclusive defense and control of any matter for which You are required to indemnify Us, including the rights to settle, and You agree to cooperate with Our defense and settlement of these claims. We will use reasonable efforts to notify You of any claim, action, or proceeding brought by a third party that is subject to the foregoing indemnification upon becoming aware of it. This paragraph shall survive termination of this Terms of Use and even after you are no longer the User of the Platform and the Services.</p>
<ol>
<li><strong>Jurisdiction</strong></li>
</ol>
<ul>
<li>1. The Terms of Use should be construed in terms of laws of the republic of India.</li>
<li>2. In case of any dispute, controversy or claim arising out of or in connection with these Terms of Use or any of the Services provided on the Platform, including any question regarding its existence, validity or termination arising out of or in connection with the Terms of Use, the Company and the User shall use all reasonable endeavors to resolve the matter amicably.</li>
<li>3. All Disputes, which are unresolved and which a party wishes to have resolved, shall be referred upon the application of either party for arbitration in accordance with the rules of the Arbitration and Conciliation Act, 1996 and amendments thereto, The number of arbitrators shall be One. The seat of the arbitration shall be Jaipur (Rajasthan).</li>
<li>4. The User agrees that in case of any dispute other than which cannot be referred to arbitration, the Courts situated at Jaipur shall have the sole jurisdiction to try such a case.</li>
</ul>
<ol>
<li><strong>Grievance &amp; Complaints</strong></li>
</ol>
<p><strong>All grievances or complaints may be made to [Sameer Lodha] at [sl@saudebazi.com]</strong></p>
<p><strong>&nbsp;</strong></p>
<p><strong>&nbsp;</strong></p>
<p><strong>&nbsp;</strong></p>


</>;
  }
}

export default TermsConditions;