
import { PrivacyTip } from '@mui/icons-material';
import { Component } from 'react';

class PrivacyPolicy extends Component {



  render() {
    return <>
    
    
    <p><strong><u>Privacy Policy</u></strong></p>
<ol>
<li><strong> Policy Statement</strong></li>
</ol>
<p>This document explains and describes how AgriGravity Technologies Private Limited (the &ldquo;Company&rdquo;) and Saudebazi (the &ldquo;Services&rdquo;) collects, uses, stores and protects the Personal Information of the User (&ldquo;You&rdquo;) or any other information from its users.</p>
<p>WE VALUE OUR USER&rsquo;S PRIVACY AND SO WE HAVE PREPARED THIS PRIVACY POLICY DOCUMENTS TO DEMONSTRATE THIS.</p>
<p>PLEASE READ THIS DOCUMENT BEFORE USING THE SERVICES OR SUBMITTING ANY INFORMATION ON THIS PLATFORM.</p>
<p>You must be 18 years or above for accessing and using the Services available on this platform. By using or accessing the Services you give consent for collection, storage, usage, disclosure or other uses of the information provided by you as per this Privacy Policy.&nbsp;</p>
<ol start="2">
<li><strong> Collection of Data</strong></li>
<li><strong> Personal Information- </strong>Personally Identifiable Information (&ldquo;Personal Information&rdquo;) such as name, email, phone numbers, date of birth etc., PAN Card Number, Aadhar Card Number, Credit Card Number, Bank Account Information etc. are collected at the time when the User opts for Registration for availing the Services provided by the Company. The Company may collect personal information while accessing or using the Services such as during creation of deal, bidding or browsing pricing information on the platform. Information may be collected if third party accounts are used for registration or log-in purposes such as Google, LinkedIN , Facebook Accounts etc.</li>
<li><strong> Content Generated By You- </strong>The Company collects all the information including chats, comments, messages and communications made by the User to the Company or on its services. It also collects information with regard to your queries and responses on our customer support. The company may also collect information generated by the user such as bookmarks, deals creation, browsing history etc. while using or accessing the Services available on the platform including third party services provided by entities other than the Company on its platform.</li>
</ol>
<p><strong>iii. Cookies: </strong>The Company or the Services may employ cookies to track your access to the Services. These technologies operate either by placing a small file which stores some information on your computer or mobile device; and/or by accessing information on your device.</p>
<ol>
<li><strong> Metadata: </strong>The Company also collects information from its users that is not personally identifiable but it somewhat helps the data to be classified in particular intelligible class or type. This information may include geographical/ location data, type of device used, Operating System of the device etc.</li>
<li><strong> Use of Information</strong></li>
</ol>
<p>Personal Information and other data is collected by the Company for the following uses:</p>
<ul>
<li>To provide, update, maintain and protect the services and platform provided by the Company..</li>
<li>As required by any applicable law, legal process or regulation.</li>
<li>To communicate with a user by responding to service requests, comments and questions.</li>
<li>To develop and provide additional features and personalized search/suggestions based on historical use and predictive models.</li>
<li>For billing, account management and other administrative matters.</li>
<li>To investigate and help prevent security issues and abuse.</li>
</ul>
<p><strong>Cookies : </strong>The Company uses cookies and similar technologies to recognize your device, for example by identifying your IP address, and to collect data such as your device&rsquo;s model, operating system and screen size, other applications installed on your device, time and date, and other information about how you use the Platform and/or Services. The Company uses this information (a) to store and access information; (b) to enable the Company to provide you with more customized services; and (c) to collect other information about your use of our Services.</p>
<ol start="4">
<li><strong> Security of Your Personal Information</strong></li>
</ol>
<p>The Company takes all reasonable and sound steps to ensure that the information- Personal Information as well as other information collected from the Users, is protected against misuse, loss, unauthorized access, modification, and/or disclosure. The Company adopts and applies appropriate data collection, storage, management practices, and security procedures to protect against unauthorized access, alteration, addition, deletion, disclosure, and/or destruction of a User&rsquo;s personal information, including their username, e mail address, password, transaction information, and any other data stored on the Platform. However, the Company does not guarantee that information will not be accessed, disclosed, altered or destroyed by any breach of any of the abovementioned safeguards or any unauthorized access to our Services.</p>
<ol start="5">
<li><strong> Disclosure or Access to Personal Information</strong></li>
</ol>
<p>The Company may disclose Personal Information to third parties such administrative services providers and business service providers or partner service providers. ANY DISCLOSURE OF PERSONAL INFORMATION TO THIRD PARTIES IS BASED ON CONFIDENTIAL BASIS.</p>
<h4><strong>6. Retention of Information</strong></h4>
<p>The Company retains the information collected from its users for as long as the information is required for fulfilling the purposes for which it was collected. Information may be retained by the Company even after deletion of Account or non-usage of the Services for meeting legal obligations, regulatory requirements and for resolving disputes between Users or for preventing fraud, for backup, archival, and/or audit purposes or any other use.</p>
<ol start="7">
<li><strong> Links To Third Party Websites/ Apps</strong></li>
</ol>
<p>The Company may provide links to third party websites or apps on its platform. Any access to the same will render the User information subject to the Privacy Policy of that particular third party service provider.</p>
<ol start="8">
<li><strong> General</strong></li>
</ol>
<p>(a) The Privacy Policy may be revised or renewed by the Company from time to time without giving prior intimation to the Users.</p>
<p>(b) The Company may also notify you of changes to the Privacy Policy via e-mail to the email address associated with your account or by way of notification on the platform. If you object to any changes to the Privacy Policy, you should immediately stop using the Platform and/or Services and close any related accounts. By continuing to access or use the Platform and/or Services after changes have become effective, you agree to be bound by the revised Privacy Policy.</p>
<p>(c) Notwithstanding anything to the contrary contained in this policy, information may be disclosed or preserved by the company to comply with any legal regulations or orders of a court of law, to prevent fraud or for protecting the safety of a person.</p>
<p>(d) In the event of any part of this Privacy Policy being held void by a court of law, all the other parts will be operable of this Privacy Policy.</p>
<ol start="9">
<li><strong> Contact</strong></li>
</ol>
<p>For any assistance or queries on the privacy policy, please contact [sl@saudebazi.com]</p>


</>;
  }
}

export default PrivacyPolicy;