import * as React from 'react';
import { useNavigate } from "react-router-dom";

import { useContext, useState, useEffect } from 'react';

import noteContext from './NoteContext';


import API from "./API";


import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Snackbar from '@mui/material/Snackbar';





function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export const UpdateProfile = () => {


    const [message, setmessage] = useState("");



    const [mobile, setMobile] = useState("");
    const [name, setName] = useState("");
    const [open, setOpen] = useState(false);


    const data = useContext(noteContext);



    const [user, setUser] = useState(data.user);



    const navigate = useNavigate();

    let token = data.token;



    const loadform = async () => {
        const res = await API.get("me", {
            headers: {
                'content-type': 'text/json', Authorization: 'Bearer ' + token
            }
        });
        const dt = res.data;


        if (dt.user.name) {
            setName(dt.user.name);


        }


        if (dt.user.mobile) {

            setMobile(dt.user.mobile);

        }

        setUser(dt.user);

        console.log(dt.user);

    };


    const handlesubmit = async () => {

        const reqdata = {

            mobile: mobile,
            name: name
        };


        if (mobile.length != 10) {
            setmessage("Please provide correct mobile.");
            setOpen(true);
            return;
        }


        if (name.length < 1) {
            setmessage("Please provide correct mobile.");
            setOpen(true);
            return;
        }


        const res = await API.post("updateprofile", reqdata, {
            headers: {
                'content-type': 'text/json', Authorization: 'Bearer ' + token
            }
        });

        const dt = res.data;

        setmessage(dt.message);


        setOpen(true);

        navigate("/");

    }




    useEffect(() => {




        loadform();;


    }, []);












    return (

        <>


            <div className="mb-3">




                Name
                <TextField
                    margin="normal"
                    required
                    fullWidth

                    value={name}




                    onChange={(e) => setName(e.target.value)}


                    autoFocus
                />



            </div>

            <div className="mb-3">




                Mobile
                <TextField
                    margin="normal"
                    required
                    fullWidth

                    value={mobile}




                    onChange={(e) => setMobile(e.target.value)}



                />



            </div>
            <div className="mb-3">

                <Button fullWidth onClick={handlesubmit} size="large" variant="outlined">Update</Button>

                <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={() => setOpen(false)}
                    message={message}

                />
            </div>








        </>

    )
}