import * as React from 'react';
import { useContext, useState, useEffect } from 'react';

import noteContext from './NoteContext';


import { Link, NavLink } from 'react-router-dom';
import API from "./API";





export default function Report() {

    function display_subject(item) {



        console.log(item);


        return <>


            <Link className="btn btn-success" to={`/check_homework/${item}`}>  {item}  </Link>

 

            <hr />


        </>;



    }



    const [MasterClassesGrades, setMasterClassesGrades] = useState([]);



    const [Teaacherssubjects, setTeaacherssubjects] = useState([]);





    const data = useContext(noteContext);

     
    const subjects = data.subjects.split(",");


    subjects.sort();


    console.log(subjects);

    let token = data.token;

    const fetchClassGrades = async () => {

        try {
            const res = await API.get("/hw/hw_report_links", {
                headers: {
                    'content-type': 'text/json', Authorization: 'Bearer ' + token
                }
            });
            setMasterClassesGrades(res.data);



        }
        catch (error) {

            console.log(error);


        }


    };






    useEffect(() => {

        fetchClassGrades();




    }, []);






    return (

        <>


            Homework report
         <hr />



            {subjects.map((t) => display_subject(t))
            }

 
 


        </>

    )
}