import * as React from 'react';
import { useContext, useState, useEffect } from 'react';

import noteContext from './NoteContext';


import { Link, NavLink } from 'react-router-dom';
import API from "./API";





export default function Teacher_report() {

    function display_subject(item) {



        console.log(item);


        return <>


            <Link className="btn btn-success" to={`/hw_teacher_report/${item}`}> hw_teacher_report {item}  </Link>

 

            <hr />


        </>;



    }



    const [MasterClassesGrades, setMasterClassesGrades] = useState([]);



    const [Teaacherssubjects, setTeaacherssubjects] = useState([]);





    const data = useContext(noteContext);


    const subjects = data.subjects.split(",");
    console.log(subjects);

    let token = data.token;

    const fetchClassGrades = async () => {

        try {
            const res = await API.get("/hw/hw_teacher_report_links", {
                headers: {
                    'content-type': 'text/json', Authorization: 'Bearer ' + token
                }
            });
            setMasterClassesGrades(res.data);



        }
        catch (error) {

            console.log(error);


        }


    };






    useEffect(() => {

        fetchClassGrades();




    }, []);






    return (

        <>


Teacher report  
         <hr />

 


            <table className="table  table-auto  ">

                
                <tbody>



                    {MasterClassesGrades.map((item, index) => (


                        <tr key={index}>

                             
                            <td>


                                <a className="btn btn-success" target='_blank' href={`${item.link}`}>  {item.subject}  {item.class}</a>






                            </td>

                        </tr>
                    ))}
                </tbody>
            </table>




        </>

    )
}