import * as React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Avatar from '@mui/material/Avatar';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Link, useNavigate } from "react-router-dom";


import PrivacyPolicy from './privacy_policy';
import TermsConditions from './terms_conditions';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';


import ChatIcon from '@mui/icons-material/Chat';

const StyledFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,

  bottom: 2,
  right: 2,

});

export default function BottomAppBar() {

  const navigate = useNavigate();


  

  return (
    <React.Fragment>


      



 
    </React.Fragment>
  );
}