import * as React from 'react';
import { useNavigate,  Link } from "react-router-dom";
import { useContext, useState, useEffect } from 'react';

import noteContext from './NoteContext';


import API from "./API";


import Button from '@mui/material/Button'; 

import Snackbar from '@mui/material/Snackbar';
 
import Form from 'react-bootstrap/Form';
 

 


export default function MasterHomework() {



    
    const [MasterClassesGrades, setMasterClassesGrades] = useState([]);


 

    const [open, setOpen] = useState(false);
    const [message, setmessage] = useState("");

    const [masterSubjects, setMasterSubjects] = useState([]); 
 


    const [homework, setHomework] = useState("");
 

    const [subject, setSubject] = useState("");





    const data = useContext(noteContext);





    const navigate = useNavigate();

    let token = data.token;



    const handlesubmit = async () => {

        const filteredArr = MasterClassesGrades.filter(e => e.isChecked === true);



        var reqdata = {

            "class_grades": filteredArr,
            "homework": homework,
            "subject": subject
        }

        const res = await API.post("/master/setMasterHomework", reqdata, {
            headers: {
                'content-type': 'text/json', Authorization: 'Bearer ' + token
            }
        });

        const dt = res.data;
        //setmasterHomeworks(dt.homeworks);



        setmessage(dt.message);


        setOpen(true);



    }


 



    const fetchClassGrades = async () => {

        try {
            const res = await API.get("/master/class_grades", {
                headers: {
                    'content-type': 'text/json', Authorization: 'Bearer ' + token
                }
            });
            setMasterClassesGrades(res.data);




        }
        catch (error) {

            console.log(error);


        }


    };





    const fetchSubjects = async () => {

        try {
            const res = await API.get("admin/master/Subjects/%", {
                headers: {
                    'content-type': 'text/json', Authorization: 'Bearer ' + token
                }
            });
            setMasterSubjects(res.data);




        }
        catch (error) {

            console.log(error);


        }


    };

    




    const handleChange = (e) => {
        const { name, checked } = e.target;







        if (name === "allselect") {
            const checkedvalue = MasterClassesGrades.map((item) => { return { ...item, isChecked: checked } });

            setMasterClassesGrades(checkedvalue);
        } else {


            const checkedvalue = MasterClassesGrades.map((item) =>
                item.name === name ? { ...item, isChecked: checked } : item);






            setMasterClassesGrades(checkedvalue);


        }


    }




    useEffect(() => {

        fetchClassGrades();

        fetchSubjects();




        


      


    }, []);






    return (

        <>

 
 New Homework 

                    <table className="table  table-auto  ">

                        <thead>

                            <tr>

                                <th>
                                    <input type="checkbox" name="allselect"
                                        checked={!MasterClassesGrades.some((element) => element?.isChecked !== true)} onChange={handleChange} />
                                    Select All
                                </th>


                                <th>
                                    Class
                                </th>

                                <th>
                                    Grade
                                </th>

                            </tr>
                        </thead>
                        <tbody>

                            {MasterClassesGrades.map((item, index) => (


                                <tr key={index}>

                                    <th> <input type="checkbox" name={item.name}
                                        checked={item?.isChecked || false}
                                        onChange={handleChange} /></th>


                                    <td>      {item.school_class} </td>

                                    <td>     {item.grade} </td>





                                </tr>
                            ))}
                        </tbody>
                    </table>











                    <div className="mb-3">
                        <Form.Label>Subject </Form.Label>
                        <Form.Select onChange={(e) => setSubject(e.target.value)} >

                            <option value=""   >Select </option>


                            {masterSubjects.map((e) => <option value={e} key={e}>{e}</option>)}
                        </Form.Select>





                    </div>

                    <div className="mb-3">


                        <Form.Label>Home Work</Form.Label>


                        <Form.Control onChange={(e) => setHomework(e.target.value)}
                            type="text"

                        />




                    </div>






                    <div className="mb-3">

                        <Button onClick={handlesubmit} size="large" variant="contained">Set Homework</Button>

                        <Snackbar
                            open={open}
                            autoHideDuration={2000}
                            onClose={() => setOpen(false)}
                            message={message}

                        />
                    </div>




 
        </>

    )
}