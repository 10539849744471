
import { Component } from 'react';

class About extends Component {



  render() {
    return <>




      <p>

        Saudebazi Group is a technology first network of verified suppliers based out of India. We help businesses
        and farmer collectives sell anytime, anywhere while offering high levels of supply assurance.
      </p>


      <p>

        Our team has over 100+ years of experience in food supply chains, technology and financial services industries.


      </p>

    </>;
  }
}

export default About;