
import { Component } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

import { useContext } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Snackbar from '@mui/material/Snackbar';

import Box from '@mui/material/Box';

import API from "./API";

import AssignmentIcon from '@mui/icons-material/Assignment';
import Table from 'react-bootstrap/Table';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import noteContext from './NoteContext';

function MasterHomeworkList() {


    const [message, setmessage] = useState("");

    const [open, setOpen] = useState(false);
    // const [data, setData] = useState([]);
    const [error, setError] = useState("%");
    const [loadingbar, setHideLoading] = useState(false);

    const [student_class, setClass] = useState("");

    const [grade, setGrade] = useState("");


    const [subject, set_subject] = useState("");

    const [homework_query, set_homework_query] = useState("");


    const [masterHomeworks_list, setmasterHomeworks] = useState([]);




    const navigate = useNavigate();
    const a = useContext(noteContext);
    let token = a.token;


    const deletehomework = async (item) => {



        const res = await API.post("admin/master/delete_master_homework", item, {
            headers: {
                'content-type': 'text/json', Authorization: 'Bearer ' + token
            }
        });
        const dt = res;

        //setmasterHomeworks(dt.homeworks);



        setmessage(dt.message);


        setOpen(true);


    }





    const fetchAPIData = async () => {




        var search_query = {
            "class": student_class,
            "grade": grade,
            "subject": subject,
            "homework_query": homework_query
        };

        console.log(search_query);






        const res = await API.post("hw/search_master_homeworks", search_query, {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            }
        });
        setmasterHomeworks(res.data.homeworks);






        setHideLoading(true);



    };

    useEffect(() => {

        if (a.login_status === null)
            navigate("/login")


        else {

            //  fetchAPIData();


        }



    }, []);



    //  if (masterHomeworks_list) return 'Loading...'





    return (<>


<Link className="btn btn-primary" to={`/add_new_master_homework`}>Add new master homework</Link>



        <div className='search_form' >


            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >


                Student class
                <input
                    name='Type Class'
                    placeholder='Type Class'
                    value={student_class}
                    onChange={(e) => setClass(e.target.value)}




                />
                Grade
                <input
                    name='GRADE'
                    placeholder='Grade'

                    onChange={(e) => setGrade(e.target.value)}
                    value={grade}



                />
                Subject
                <input
                    name='subject'

                    onChange={(e) => set_subject(e.target.value)}


                    value={subject}

                />
                Home Work
                <input



                    value={homework_query}

                    onChange={(e) => set_homework_query(e.target.value)}


                />


                <Button onClick={fetchAPIData} variant="contained">Fetch Data  </Button>

            </Box>
        </div >




        {masterHomeworks_list.length > 0 &&
            <div className='table-responsive'>

                <Table responsive striped bordered hover size="sm">



                    <thead>

                        <tr>

                            <th> Class </th>
                            <th> Grade </th>
                            <th> Subject</th>
                            <th> HomeWork</th>
                            <th> Created_at</th>
                            <th> Action </th>
                            <th> Action </th></tr>
                    </thead>

                    <tbody>

                        {masterHomeworks_list.map((item, index) => (


                            <tr key={index}>

                                <td>   {item.school_class}   </td>
                                <td>      {item.grade} </td>
                                <td>   {item.subject} </td>



                                <td>   {item.homework} </td>

                                <td>   {item.created_at} </td>


                                <td>



                                    <Link className="btn btn-primary" to={`/AsignHomeWork/${item.id}`}><AssignmentIcon /></Link>

                                </td>

                                <td>

                                    <Button onClick={() => deletehomework(item)} variant="contained"> <DeleteForeverIcon /></Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        }

    </>


    );

};



export default MasterHomeworkList;