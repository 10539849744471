


import { Routes, Route } from 'react-router-dom';
import React, { useEffect } from 'react';



//import Home from './components/Home';




import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



import NoteState from "./components/NoteState";
import { Logout } from './components/logout';
import BottomAppBar from './components/footer';
import { Helmet } from "react-helmet";

import AppBar from '@mui/material/AppBar';

import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import Box from '@mui/material/Box';

import DrawerAppBar from "./components/Drawer";
import { Description } from '@mui/icons-material';


 import    MarkComplete   from  './components/MarkComplete';



 
 import Teacher_report from './components/Teacher_report';
 
 import Report from './components/report';
 import ReportStudent from './components/ReportStudent';
 
import PrivacyPolicy from './components/privacy_policy';
import TermsConditions from './components/terms_conditions';



import Subscription from './components/Subscription';

import About from './components/About';

import { Profile } from './components/Profile';

import { Login } from './components/Login';
import { C404 } from './components/404';
import { UpdateProfile } from './components/updateProfile';
import { UpdateStudent } from './components/updateStudent';

import { SendOtp } from './components/sendOTP';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query'
import { EditStudent } from './components/editstudent';
import MasterHomeWork from './components/MasterHomeWork';
import MasterHomeworkList from './components/MasterHomeWorkList';

import AsignHomeWork from './components/AsignHomeWork';
import Teachers from './components/Teachers';

import SearchStudents from './components/SearchStudents'

//import CommodityDetails from './components/CommodityDetails';
//import CommodityGradeBlock from './components/CommodityGradeBlock';


const LazyHome = React.lazy(() => import('./components/Home'));

const LazyStudentDetails = React.lazy(() => import('./components/StudentDetails'));

const LazyHomework = React.lazy(() => import('./components/Homework'));

//const LazyAsignHomeWork=React.lazy( () => import('./components/AsignHomeWork') );




function App() {


  const queryClient = new QueryClient()

  return (
    <div className="App ">


      <QueryClientProvider client={queryClient}>


        <NoteState>

          <DrawerAppBar />

          <Toolbar />



          <Container className='page_wrapper mt-5'>
            <Row>
              <Col>


                <Routes>



                  <Route path="/logout" element={<Logout />}></Route>
                  <Route path="/C404" element={<C404 />}></Route>


                  <Route path="/privacy_policy" element={<PrivacyPolicy />}></Route>


                  <Route path="/terms_conditions" element={<TermsConditions />}></Route>




                  <Route path="/Teacher_report" element={<Teacher_report />}></Route>
                  <Route path="/Student_report" element={<Report />}></Route>
                  <Route path="/Student_print_report" element={<SearchStudents />}></Route>

                  

                  <Route path="/Subscription" element={<Subscription />}></Route>

                  <Route path="/about" element={<About />}></Route>
                  <Route path="/Profile" element={<Profile />}></Route>

                  <Route path="/update_profile" element={<UpdateProfile />}></Route>
                  <Route path="/editstudent/:sr" element={<EditStudent />}></Route>

                  <Route path="/updatestudent/:sr" element={<UpdateStudent />}></Route>


                  <Route path="/AsignHomeWork/:homework_id" element={<AsignHomeWork />}></Route>


                  <Route path="/MarkComplete/:school_class" element={<MarkComplete />}></Route>
                  <Route path="/check_homework/:school_class" element={<MarkComplete />}></Route>

                  


                  <Route path="/add_new_master_homework" element={<MasterHomeWork />}></Route>



                  <Route path="/homeworks" element={<MasterHomeworkList />}></Route>



                  <Route path="/teachers" element={<Teachers />}></Route>

                  <Route path="/search_student" element={<SearchStudents />}></Route>




                  <Route path="/student/:sr" element={
                    <React.Suspense fallback="Loading... please wait ">

                      <LazyStudentDetails />

                    </React.Suspense>

                  }>
                  </Route>

                  <Route path="/homework/:sr" element={
                    <React.Suspense fallback="Loading... please wait ">

                      <LazyHomework />

                    </React.Suspense>

                  }>

                  </Route>







                  <Route path="/" element={
                    <React.Suspense fallback="Loading... please wait ">

                      <LazyHome />

                    </React.Suspense>

                  }></Route>


                  <Route path="/login" element={<Login />}></Route>
                  <Route path="/SendOtp" element={<SendOtp />}></Route>


                </Routes>




              </Col>
            </Row>
          </Container>


          <BottomAppBar />


        </NoteState>

      </QueryClientProvider>




    </div >
  );
}

export default App;






